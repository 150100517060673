import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ZrNewsDocumentDialog from './zr-news-document-dialog/zr-news-document-dialog.vue';
import { CONST } from '@/shared/utils/Constants';
// import { DefaultViewHelper } from '../ViewHelper';
// import D4yTableMini from '@/components/d4y-table-mini/d4y-table-mini.vue';
import D4yTableMini from '@/components/d4y-table-mini/d4y-table-mini.vue';


import GeneralUtils from '@/shared/utils/generalUtils';

const name = 'zrNewsDocument-view';
const zrNewsModule = namespace('zrNews');
const authModule = namespace('auth');

@Component({ name: name, components: { ZrNewsDocumentDialog, D4yTableMini } })
export default class ZrNewsDocumentsComponent extends Vue {
  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroup!: any;
  @authModule.Getter('isUserSupport')
  private isUserSupport!: any;
  @authModule.Getter('isUserGsUser')
  private isUserGsUser!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;

  zrNewsDocumentsIsLoading = true;
  zrNewsDocumentsSearchParams = {};

  @zrNewsModule.Action('deleteZrNewsFile')
  private actionDeleteZrNewsFile!: any;
  @zrNewsModule.Action('getNewsFileDownloadLink')
  private actionGetNewsFileDownloadLink!: any;

  @Prop({ default: () => [] })
  private files!: any;
  @Prop({ default: () => {} })
  private zrNews!: any;

  @Prop({ default: false })
  private disabled!: boolean;


  @Prop({ default: CONST.emptyGuid })
  private locationId!: string;
  @Prop({ default: '' })
  private zrNewsCreatedId!: string;

  @Ref('zrNewsDocument-add-edit-ref')
  private zrNewsDocumentAddEditRef!: ZrNewsDocumentDialog;

  get zrNewsFilesDocsCount() {
    return this.zrNews.news_files.filter((x:any)=> x.bucket_name == 'gsp-news-anlagen').length;
  }

  get zrNewsId() {
    return this.$route.params['zrNewsId'];
  }

  get isNewsCreate() {
    return this.zrNewsId == '0' || !this.zrNewsId;
  }


get zrNewsFiles() {
  return this.files;
}

get zrNewsDocumentsTotal() {
  return this.files?.length || 0;
}

  created() {
    setTimeout(() => {
      this.zrNewsDocumentsIsLoading = false;
    }, 1000);
  }

  async mounted() {


    // var promiseAll = [this.getZrNewsDocuments()];
    // await Promise.all(promiseAll);
  }
  private async getZrNewsDocuments() {
  }
  private image = {
    src: '',//'cv_document.svg',
    header: '',
    height: 0,//85,
    width: 140,
  };

  async download(item: any) {
    console.log('item :>> ', item);
    let attachmentPayload = {
      FileName: item.filename,
      BucketName: item.bucket_name,
      DisplayName: item.display_name,
      OriginalName: item.original_name,
      IsSaveOnDisk: true
    };
    await this.actionGetNewsFileDownloadLink(attachmentPayload).then((res:any)=> {
      // console.log('actionGetNewsFileDownloadLink attachmentFile res :>> ', res);
    });
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      { text: this.$t('name'), value: 'display_name' },
      { text: this.$t('filename'), value: 'original_name' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
        align: 'center',
      },
    ];
    return headers;
  }

  private async zrNewsDocumentsUpdateOptions(newVal: any, oldVal: any) {
  }

  //#region AddEdit zrNewsDocument
  zrNewsDocumentDialog = {
    show: false,
    showDelete: false,
    model:  { ...this.zrNews }
  };

  onAdd() {
    this.add();
  }

  add() {
    this.zrNewsDocumentDialog.model =  { ...this.zrNews };
    this.zrNewsDocumentDialog.show = true;
  }

  async zrNewsDocumentDialogOnClose(data: any) {
    this.zrNewsDocumentDialog.model = { ...this.zrNews };
    this.zrNewsDocumentDialog.show = false;
    // called on dialog close
    if (data?.updateList) {
      // this.getZrNewsDocuments();
    }
  }

  editZrNewsDocument(editZrNewsDocument: any) {
    this.zrNewsDocumentDialog.model = { ...editZrNewsDocument };
    this.zrNewsDocumentDialog.show = true;
  }

  async zrNewsDocumentDialogOnUpdate(zrNewsDocument: any, zrDocumentRef: any, fileName: string) {
    this.zrNewsDocumentDialog.show = false;
    let ind = this.zrNews.news_files.findIndex((x: any)=> x.id == zrNewsDocument.id);
    if (ind > -1) {
      this.zrNews.news_files.splice(ind,1, zrNewsDocument);
    }

    if (this.isNewsCreate) {
      this.$emit('update:document-on-create', zrDocumentRef, fileName);
    }

    // (GSP-189) can access `document-upload` REF easy in dialog, but need many levels to access it from `zr-News` component
    // console.log('this.$refs documents refasfasfd sa:>> ', (this.$refs['zrNewsDocument-add-edit-ref'] as any).$refs['document-upload-cmp-zr-news-doc']);

    // zrNewsDocument.zr_news_id = +this.$route.params['zrNewsId'];
    // await this.actionUpdateZrNewsDocument(zrNewsDocument).then(({ id }: any) => {
    //   // this.zrNewsDocumentAddEditRef.$data.isLoading = false;
    //   this.getZrNewsDocuments();
    // });
  }

  deleteZrNewsDocument(item: any) {
    console.log('item :>> ', item);

    let payload = {
      FileName: item.filename,
      BucketName: item.bucket_name,
      ZrNewsFileId: item.id,
    }

    this.$confirm
      .open(`${this.$t('delete_dialog_title')}`, `${this.$t('delete_dialog_text')}`, {
        cancelText: this.$t('delete_dialog_cancel'),
        okText: this.$t('delete_dialog_ok'),
      })
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteZrNewsFile(payload).then((response: any) => {
            // console.log('response AFTER delete:>> ', response);

            if (response.isValid && response.result) {
              let index = this.zrNews.news_files.findIndex((x: any)=> x.id == response.result.id);
              if (index > -1) {
                this.zrNews.news_files.splice(index,1);
              }
            }

            // this.getZrNewsDocuments();
          });
        }
      });
  }

  //#endregion
}
