var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.zrNewsDocumentDialog.show || _vm.isNewsCreate)?_c('zr-news-document-dialog',{ref:"zrNewsDocument-add-edit-ref",attrs:{"dialog":_vm.zrNewsDocumentDialog.show,"zrNewsCreatedId":_vm.zrNewsCreatedId},on:{"click:update":_vm.zrNewsDocumentDialogOnUpdate,"click:close":_vm.zrNewsDocumentDialogOnClose},model:{value:(_vm.zrNewsDocumentDialog.model),callback:function ($$v) {_vm.$set(_vm.zrNewsDocumentDialog, "model", $$v)},expression:"zrNewsDocumentDialog.model"}}):_vm._e(),_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{staticClass:"pa-0"}),_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('d4y-table-mini',{attrs:{"showToolbar":_vm.isUserInAzureAdminGroup,"image":_vm.image,"headers":_vm.headers,"items":_vm.zrNewsFiles,"AllowSearch":false,"AllowAdd":_vm.isUserInAzureAdminGroup && _vm.zrNewsFilesDocsCount < 5,"allowEdit":_vm.isUserInAzureAdminGroup,"allowDelete":_vm.isUserInAzureAdminGroup,"haveMoreActions":true,"disableSort":true,"serverItemsLength":_vm.zrNewsDocumentsTotal,"loading":_vm.zrNewsDocumentsIsLoading,"options":_vm.zrNewsDocumentsSearchParams.dataOption},on:{"click:delete":_vm.deleteZrNewsDocument,"action:add":_vm.onAdd,"click:edit":_vm.editZrNewsDocument,"update:options":_vm.zrNewsDocumentsUpdateOptions},scopedSlots:_vm._u([{key:"display_name",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"primary--text",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.isUserInAzureAdminGroup? _vm.editZrNewsDocument(item) : _vm.download(item)}}},[_vm._v(" "+_vm._s(item.display_name)+" ")])]}},{key:"text",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"erstellt_am",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithLocale")(item.erstellt_am,_vm.$i18n.locale))+" ")]}},{key:"more-actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isUserOnlyGs)?_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","loading":item.item.isLoadingDocument},on:{"click":function($event){$event.stopPropagation();return _vm.download(item.item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('download_action')))])])]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"v-data-footer py-3"},[_c('div',{staticClass:"ml-auto text-body-2 v-data-footer__pagination"},[_vm._v(" "+_vm._s(_vm.$t('number_of_uploaded_docs'))+": "),_c('b',[_vm._v(_vm._s(props.pagination.itemsLength))]),(_vm.isUserInAzureAdminGroup)?_c('span',{staticClass:"text-body-1 pl-2"},[_vm._v("(Max 5)")]):_vm._e()])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }