import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import zrNew, { ZrNews } from '@/shared/model/zrNews';
import D4yEdit from '@/components/d4y-edit/d4y-edit.vue';
import { CONST } from '@/shared/utils/Constants';
import { ROUTES } from '@/router/routesEnum';
import DateUtils from '@/shared/utils/DateUtils';
import DateField from '@/components/_common/date-field/DateField.vue';
import DocumentPreview from '@/views/home/document-preview/document-preview.vue';
import html2canvas from 'html2canvas';
import GeneralUtils, { debounceAsync } from '@/shared/utils/generalUtils';
import NumberUtils from '@/shared/utils/numberUtils';
import DocumentUpload from '@/components/_common/document-upload/document-upload.vue';
import ZrNewsDocuments from './zr-news-documents/zr-news-documents.vue';

const name = 'zr-news';
const logger = new Logger(name);
const authModule = namespace('auth');
const zrNewsModule = namespace('zrNews');

const delay = GeneralUtils.delay; // delay function

@Component({
  name: name,
  components: {
    D4yEdit,
    DateField,
    DocumentPreview,
    DocumentUpload,
    ZrNewsDocuments
  },
})
export default class ZrNewsView extends Vue {
  @Ref('document-upload-cmp-zr-news')
  private documentUploadCmpRef!: any;
  @Ref('observer-add-edit-zrNews-form')
  private addEditZrNewsForm!: any;

  @zrNewsModule.Action('uploadNewsDocument')
  private actionUploadNewsDocument!: any;
  @zrNewsModule.Action('getNewsFileDownloadLink')
  private actionGetNewsFileDownloadLink!: any;


  //uploadNewsDocument
  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroup!: any;
  @authModule.Getter('isUserSupport')
  private isUserSupport!: any;
  @authModule.Getter('isUserGsUser')
  private isUserGsUser!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;

  @zrNewsModule.Action('getZrNews')
  private actionGetZrNews!: any;

  @zrNewsModule.Action('updateZrNews')
  private actionUpdateZrNews!: any;
  @zrNewsModule.Action('upsertZrNewsReadInfo')
  private actionUpsertZrNewsReadInfo!: any;

  private news: Array<any> = [];


  get zrNewsFilesArr() {
    return this.zrNews.news_files.filter(x=> x.bucket_name == 'gsp-news-anlagen');
  }

  private isRecordLoaded = false;
  private isPdfLoaded = false;
  private isShowBackupImage = false;

  get zrNewsId() {
    return this.$route.params['zrNewsId'];
  }

  get isNewsCreate() {
    return this.zrNewsId == '0' || !this.zrNewsId;
  }

  async created() {
    if ((!this.isUserInAzureAdminGroup && !this.isUserGsUser)) {
      this.goToNewsList();
    }

    if (!this.isNewsCreate) {
      await this.getZrNews();
    } else {
      this.isRecordLoaded = true;
    }
  }

  // when change record via `next/previous` button the `mounted` method will NOT be called
  async mounted() {


  }

  showBackupImage(documentType: string) {
    this.isPdfLoaded = true; // ignore PDF loading
  }

  async getZrNews() {
    this.isRecordLoaded = false; // change loading flag when change `zrNews` via `next/previous` buttons
    await this.actionGetZrNews(this.zrNewsId)
      .then(async (response: any) => {
        console.log('zre Document response :>> ', response);
        this.zrNews = Object.assign({}, zrNew.parse(response));
        let image = this.zrNews.news_files.find(x=> x.bucket_name == 'gsp-news-images');
        console.log('image :>> ', image);
        if (image) {
          let imagePayload = {
            FileName: image.filename,
            BucketName: image.bucket_name,
            DisplayName: image.display_name,
          }
          await this.actionGetNewsFileDownloadLink(imagePayload).then((res:any)=>
            {
            console.log('actionGetNewsFileDownloadLink image res :>> ', res);
            this.zrNews.news_image = res;
            this.imgParams.src = res;
            // console.log('this.zrNews Object.assign:>> ', Object.assign({},this.zrNews));
          }).finally(()=> {
            if (this.isUserOnlyGs && this.isUnread) {
              this.markAsRead();
            }
          });
        } else {
          if (this.isUserOnlyGs && this.isUnread) {
            this.markAsRead();
          }
        }

        // let attachmentFile = this.zrNews.news_files.find(x=> x.bucket_name == 'gsp-news-anlagen');
        // console.log('image :>> ', attachmentFile);
        // if (attachmentFile) {
        //   let attachmentPayload = {
        //     FileName: attachmentFile.filename,
        //     BucketName: attachmentFile.bucket_name,
        //     DisplayName: attachmentFile.display_name,
        //   }
        //   await this.actionGetNewsFileDownloadLink(attachmentPayload).then((res:any)=> {
        //     console.log('actionGetNewsFileDownloadLink attachmentFile res :>> ', res);
        //     this.zrNews.news_attachment = res;
        //   });
        // }
        //this.zrNews.news_files[0]?.display_name

      })
      .catch((err:any) => {
        logger.error(err);
      })
      .finally(() => {
        this.isRecordLoaded = true;
      });
  }
  private zrNews: ZrNews = zrNew.parse({});

  isLoading = false;
  titleInfo = 'empty';

  get image() {
    return {
      src: 'gs_details.svg',
      header: '',
      width: 230,
      height: 210,
    };
  }


  imgParams =  {
    src: 'belege_stub_big.png',
    height: this.dynamicImageSize.height,
    width: this.dynamicImageSize.width,
  };
  get newsImg() {
    return this.imgParams;
  }

  get allowFalseForCheckboxes() {
    return this.zrNews.is_valid_for_supplier || this.zrNews.is_valid_for_member || this.zrNews.is_valid_for_intern;
  }

  @Watch('$vuetify.breakpoint.width')
  public async onOptionsFilterChanged(newVal: any, oldVal: any) {
    // console.log('newVal :>> ', newVal);
    // console.log('oldVal :>> ', oldVal);
    this.recreateImage();
  }

  /**
   * xs < 600px, sm 600-960px, md 960 - 1264
   */
  get textAreaHeight() {
    let resultHeight = 500;
    if (this.$vuetify.breakpoint.xlOnly) {
      resultHeight = 595;
     }
    else if (this.$vuetify.breakpoint.lgOnly) {
      resultHeight = 555;
     }
    else if (this.$vuetify.breakpoint.mdOnly) {
      resultHeight = 555;
     }
     return resultHeight;
  }

  get dynamicImageSize() {
    // if (this.$vuetify.breakpoint.xlOnly) {
    //   return { height: 610, width: 450 };
    // } else if (this.$vuetify.breakpoint.lgOnly && this.$vuetify.breakpoint.width > 1580) {
    //   return { height: 510, width: 350 };
    // } else if (this.$vuetify.breakpoint.lgOnly && this.$vuetify.breakpoint.width < 1580) {
    //   return { height: 450, width: 300 };
    // } else if (this.$vuetify.breakpoint.mdOnly) {
    //   return { height: 410, width: 250 };
    // }
    let result = { height: 265, width: 320 };
     if (this.$vuetify.breakpoint.xlOnly) {
      result = { height: 305, width: 340 };
     }
    else if (this.$vuetify.breakpoint.width < 1440 && this.$vuetify.breakpoint.width >= 1264) {
      result = { height: 265, width: 300 };
    }
    else if (this.$vuetify.breakpoint.width < 1264 && this.$vuetify.breakpoint.width > 1100) {
      // console.log('100000 :>> ');
      result = { height: 265, width: 300 };
    }
    else if (this.$vuetify.breakpoint.width < 1100) {
      // console.log('100000 :>> ');
      result = { height: 265, width: 230 };
    }

    return result;
    // return { height: 515, width: 350 };
  }

  recreateImage() {
    let x = this.dynamicImageSize;
    // console.log('x.height :>> ', x.height);
    // console.log('this.imgParams.height :>> ', this.imgParams.height);
    // let obj = {...this.imgParams, ...x}
    if (x.height != this.imgParams.height) {
      let obj = {...this.imgParams, ...x}
      this.imgParams = obj;
    }
  }

  get noticeHeaders() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      { text: this.$t('busy_type'), value: 'belegTyp', sortable: false, width: '209' },
      { text: this.$t('date'), value: 'datumInvoice', sortable: false, align: 'center' },
      { text: this.$t('gross_amount'), value: 'bruttobetrag', sortable: false, align: 'right' },
    ];
    return headers;
  }

  //#region Logic related to `status` dialog

  updateNewsFormData(news: any) {
    logger.log('result updateNewsFormData (after `status dialog` update):>> ', news);
    const responseDocument = document;
    // this.zrDocument.bonusgutschrift = document.bonusgutschrift;
    // this.zrDocument.skontierfaehig = document.skontierfaehig;
    // this.zrDocument.faelligkeit = document.faelligkeit;

    // this.zrDocument.status_Freigabe = responseDocument.status_Freigabe;
    // this.zrDocument.bearbeit_Am = responseDocument.bearbeit_Am;
    // this.zrDocument.bearbeit_Von = responseDocument.bearbeit_Von;
    // if (!this.zrDocument.kommission_Nummer) {
    //   this.zrDocument.kommission_Nummer = responseDocument.kommission_Nummer;
    // }

    // this.zrDocument.text_nichtbuchen = responseDocument.text_nichtbuchen;
  }

  showMenu = false;
  closeMenu() {
    this.showMenu = false;
  }

  //#endregion



  changeValidFromDate(value:any) {
    this.zrNews.valid_from = value || undefined;
  }

  changeValidUntilDate(value:any) {
    this.zrNews.valit_until = value || undefined;
  }


  private navigateToNewCreatedRecord(id: any) {
     this.$router.push({
      name: ROUTES.news,
      params: { zrNewsId: id +'' },
    });
  }

  private zrNewsCreatedId:number = 0;
  async onClickedUpdate() {


    const result = await this.addEditZrNewsForm.validate();
    if (result) {
      this.isLoading = true;

      console.log('this.zrNews :>> ', this.zrNews);

      this.zrNews.id = +this.zrNewsId;
      await this.actionUpdateZrNews(this.zrNews)
        .then((response: any) => {
          console.log('response :>> ', response);
          this.zrNewsCreatedId =response.result.id;
          if (this.isNewsCreate) {
            this.imageUploadCmpRef.upload();
            // console.log('this.documentUploadCmpRef :>> ', this.documentUploadCmpRef);
            // console.log('this.documentUploadCmpRef == null :>> ', this.documentUploadCmpRef == null);

            if ((this.imageUploadCmpRef.document == null && this.documentUploadCmpRef == null && this.dialogFstDocRef == null) ||
              (this.imageUploadCmpRef.document != null && this.documentUploadCmpRef == null && this.dialogFstDocRef == null)) {
               this.navigateToNewCreatedRecord(this.zrNewsCreatedId);
            } else if (this.imageUploadCmpRef.document == null && (this.documentUploadCmpRef?.document != null || this.dialogFstDocRef != null)) {

              // if
              // this.documentUploadCmpRef.upload();
              // (GSP-189) give a little time to pass `zrNewsCreatedId` to dialog via Props
              setTimeout(() => {
                this.dialogFstDocRef.upload();
              }, 1000);

            }
          }

          // this.zrNewsCreatedId =response.result.id;
          // this.$router.push({
          //   name: ROUTES.news,
          //   params: { zrNewsId: response.result.id },
          // });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  private dialogFstDocRef: any = null;
  updateDocumentOnCreate(documentRef:any, fileName: string) {
    // console.log(' documentRef :>> ',  documentRef);
    this.dialogFstDocRef = documentRef;
    this.attachmentFileName = fileName;
    // console.log(' this.dialogFstDocRef :>> ',  this.dialogFstDocRef);
    // console.log(' this.documentUploadCmpRef :>> ',  this.documentUploadCmpRef);
  }

  get isUnread() {
    return this.zrNews.news_read_info.length == 0 || (this.zrNews.news_read_info.length == 1 && this.zrNews.news_read_info.every(x=> !x.is_read));
  }

  markAsUnRead() {
    logger.log('markAsUnRead');
    this.changeReadStatus(false);
  }

  isMarkingRead = false;
  markAsRead() {
    logger.log('markAsRead');
    this.changeReadStatus(true);
  }

  changeReadStatus(is_read: boolean){
    let payload: any = {
      zr_news_id: this.zrNews.id,
      is_read: is_read,
      user_id: "",
      UpdateRead: true
    }
    if (this.zrNews.news_read_info.length ==1) {
      payload.id = this.zrNews.news_read_info[0].id;
    }

    this.isMarkingRead = true;
    this.actionUpsertZrNewsReadInfo(payload).then((result:any) => {
      console.log('actionUpsertZrNewsReadInfo resultresult:>> ', result);
      this.zrNews.news_read_info.splice(0,1,result.result);
    }).catch((err:any) => {
      logger.error(err);
    }).finally(()=> {
      this.isMarkingRead = false;
    });
  }

  goToNewsList() {
    this.$router.push({
      name: ROUTES.newsList,
    });
  }



  //#region Next/previous logic
  get currentIdPosition() {
    return this.news.findIndex((x: any) => x.id == this.zrNewsId);
  }

  get isFirstNews() {
    // console.log('this.news :>> ', this.news);
    // console.log(
    //   'this.news ID :>> ',
    //   this.news.findIndex((x: any) => x.id == this.zrNewsId)
    // );
    return this.currentIdPosition == 0;
  }

  get isLastNews() {
    return this.currentIdPosition == this.news.length - 1;
  }

  goToPrev() {
    const prevId = this.news[this.currentIdPosition - 1].id;

    this.$router.push({
      name: ROUTES.news,
      params: { zrNewsId: prevId },
    });
    this.getZrNews();
  }

  goToNext() {
    const nextId = this.news[this.currentIdPosition + 1].id;

    this.$router.push({
      name: ROUTES.news,
      params: { zrNewsId: nextId },
    });
    this.getZrNews();
  }
  //#endregion


  getTitle(value: string) {
    if (value != null && value != '-') {
      // return this.$t('edited_by') + ':  ' + value + ', ';
      return value + ', ';
    } else {
      return '';
    }
  }

  submitForm(e: any) {
    e.preventDefault(); // (GSP-076) prevent reload page when click on any button in PDF preview (vue-pdf-app) toolbar
  }

//#region Logic to upload image
  @Ref('image-upload-cmp')
  private imageUploadCmpRef!: any;

  isLoadingImage: boolean = false;
  private isImageUploaded = false;
  private imageData: any = {}; // Document = { ...DocumentEmpty };
  private imageModel!: any; //Document

  get hasUploadedImage() {
    return this.isImageUploaded;
  }

  async uploadImage(payload: any) {
    logger.debug('uploadImage');
    // payload.id = this.model.id;
    // payload.parentId = this.parentId;
    this.isLoadingImage = true;
    payload.FileName = payload.document.name;
    payload.BucketName = 'gsp-news-images';
    payload.ZrNewsId = this.zrNewsCreatedId || this.zrNews.id;

    await this.actionUploadNewsDocument(payload)
      .then((result: any) => {
        logger.debug('uploadImage result :>> ', result);
        if (result.isValid) {
          // this.model.file_id = result.id;
          // this.setDocumentFileInfo(result.result); // `result.result` contains DocumentInfo (Name, Extension, Size) from successfully uploaded file on the Backend
          this.isImageUploaded = true;
          this.zrNews.news_image = this.onImageUploadUrl;
          this.imgParams.src = this.onImageUploadUrl;
          // Vue.set(this.imgParams,'src', result.result.minioLink);
          // this.$emit('document:uploaded'); // need to load list documents (not our case)
          this.$emit('document:getData', this.documentData); // better emit result of Document creation + File Upload here than make extra request from `*-documents` table
          // also Important to get the last changes when open dialog, if document was changed by someone else

        }
      })
      .finally(() => {
        if (this.isNewsCreate && this.dialogFstDocRef != null) { // this.documentUploadCmpRef?.document != null
          this.dialogFstDocRef.upload();
        }
        this.isLoadingImage = false;
        // this.resetDocumentData();
        // this.onClickedClose();
      });
  }

  private onImageUploadUrl = '';
  private async fileInputImageChange(isInputHasFile: boolean, fileInfo: any) {
    // console.log('this.imageUploadCmpRef.document :>> ', this.imageUploadCmpRef.document);
    // console.log('fileInfo :>> ', fileInfo);
    // console.log(' URL.createObjectURL(fileInfo) :>> ',  URL.createObjectURL(fileInfo));
    const blob = new Blob([fileInfo], { type: "image/png" });
    this.onImageUploadUrl= URL.createObjectURL(fileInfo);
    // this.imgParams.src = URL.createObjectURL(event!.target!.files[0])

    if (isInputHasFile && fileInfo && fileInfo.size <= CONST.maxFileSize5MB) {
      logger.debug('fileInputImageChange');
      // this.setDocumentInfo();
      if (!this.isNewsCreate) {
        this.imageUploadCmpRef.upload();
      }
      return;
      await this.changeVoucherDocumentAddEditDialogOnUpdate()
        .then(async (result: any) => {
          logger.debug('onSave result:>> ', result);
          // this.model.id = result.id;
          // this.documentData = { ...result.result };
          // this.uploadDocumentBtnClick();
        })
        .catch((err:any) => {});
      // await this.actionUpdateDocument(this.documentData)
      //   .then(async (result: any) => {
      //     logger.debug('onSave result:>> ', result);
      //     this.model.id = result.id;
      //     this.documentData = { ...result.result };
      //     this.uploadDocumentBtnClick();
      //   })
      //   .catch((err: any) => {});
    }
  }


//#endregion

  async downloadFile(e: any) {
    let attachmentFile = this.zrNews.news_files.find(x=> x.bucket_name == 'gsp-news-anlagen');
    // console.log('image :>> ', attachmentFile);
    if (attachmentFile) {
      let attachmentPayload = {
        FileName: attachmentFile.filename,
        BucketName: attachmentFile.bucket_name,
        DisplayName: attachmentFile.display_name,
        OriginalName: attachmentFile.original_name,
        IsSaveOnDisk: true
      }
      await this.actionGetNewsFileDownloadLink(attachmentPayload).then((res:any)=> {
        // console.log('actionGetNewsFileDownloadLink attachmentFile res :>> ', res);
        // this.zrNews.news_attachment = res;
      });
    }
  }

  //#region Logic to upload document
  isLoadingDocument: boolean = false;
  private isDocUploaded = false;
  private documentData: any = {}; // Document = { ...DocumentEmpty };
  private model!: any; //Document

  get fileName(){
    const attachment = this.zrNews.news_files.find(x=> x.bucket_name == 'gsp-news-anlagen');
    return attachment ? attachment.display_name : '';
  }

  private documentFileInfo: {
    documentName: string;
    documentType: string;
    documentSize: string;
  } = {
    documentName: '',
    documentType: '',
    documentSize: '',
  };
  get hasUploadedDocument() {
    return this.isDocUploaded;
  }

  get uploadedDocumentInfo() {
    if (!this.documentFileInfo.documentName) return '';
    return `${this.documentFileInfo.documentName}.${this.documentFileInfo.documentType} (${(
      +this.documentFileInfo.documentSize / 1000
    ).toFixed(1)} KB)`;
  }

  setDocumentFileInfoOnOpen() {
    // used in created
    const hasDocument = true;
      // this.zrNews.document_file_id && this.zrNews.document_file_id != CONST.emptyGuid;
    if (hasDocument) {
      // this.isDocUploaded = true;

      // this.documentFileInfo!.documentName = this.changeVoucherDocument.document_name;
      // this.documentFileInfo!.documentType = this.changeVoucherDocument.document_type;
      // this.documentFileInfo!.documentSize = this.changeVoucherDocument.document_size;
    }
  }

  private setDocumentFileInfo(docFileInfo: any) {
    this.documentFileInfo!.documentName = docFileInfo.documentName;
    this.documentFileInfo!.documentType = docFileInfo.documentType;
    this.documentFileInfo!.documentSize = docFileInfo.documentSize;
    this.documentData = { ...this.documentData, ...this.documentFileInfo }; /// TODO: (LOW) write documentFileInfo in DocumentData (maybe we don't need that and delete in future)
  }

  private attachmentFileName = '';
  private onNameInputChange(value: any ) {
    console.log('onNameInputChange value:>> ', value);
    this.attachmentFileName = value;
  }

  async uploadDocument(payload: any) {
    console.log('payload :>> ', payload);
    logger.debug('uploadDocument');
    // payload.id = this.model.id;
    // payload.parentId = this.parentId;
    this.isLoadingDocument = true;
    payload.FileName = this.attachmentFileName;
    payload.BucketName = 'gsp-news-anlagen';
    payload.ZrNewsId = this.zrNewsCreatedId || this.zrNews.id;

    await this.actionUploadNewsDocument(payload)
      .then((result: any) => {
        logger.debug('uploadDocument result :>> ', result);
        if (result.isValid) {
          // this.model.file_id = result.id;
          this.setDocumentFileInfo(result.result); // `result.result` contains DocumentInfo (Name, Extension, Size) from successfully uploaded file on the Backend
          this.isDocUploaded = true;
          this.zrNews.news_attachment = result.result.minioLink;
          this.pushFileInZrNewsFiles(result.result);
          // this.$emit('document:uploaded'); // need to load list documents (not our case)
          this.$emit('document:getData', this.documentData); // better emit result of Document creation + File Upload here than make extra request from `*-documents` table
          // also Important to get the last changes when open dialog, if document was changed by someone else
        }
      })
      .finally(() => {
        this.isLoadingDocument = false;
        this.resetDocumentData();
        this.onClickedClose();

            // this.zrNewsCreatedId =response.result.id;
        if(this.isNewsCreate) {
            this.navigateToNewCreatedRecord(this.zrNewsCreatedId);
        }
      });
  }
  private pushFileInZrNewsFiles(file: any){
    let isFilePresent = this.zrNews.news_files.find((x:any) => x.id == file.id);
    if (!isFilePresent) {
      this.zrNews.news_files.push(file);
    }
  }

  private setDocumentInfo() {
    this.documentData!.type = "pdf"; //this.documentType;
    this.documentData!.for = 'all'; // TODO: (EGRUP-143) temporary solution until find out logic about `FOR` field
    //this.documentData.title = this.model.title;
  }
  //
  //size:5120|required|ext:pdf,jpg
  // size:5120|required|ext:pdf,jpg
  private async fileInputChange(isInputHasFile: boolean, fileInfo: any) {
    if (isInputHasFile && fileInfo && fileInfo.size <= CONST.maxFileSize5MB) {
      logger.debug('fileInputChange');
      this.setDocumentInfo();
      if (!this.isNewsCreate) {
        this.documentUploadCmpRef.upload();
      }
      return;
      await this.changeVoucherDocumentAddEditDialogOnUpdate()
        .then(async (result: any) => {
          logger.debug('onSave result:>> ', result);
          // this.model.id = result.id;
          // this.documentData = { ...result.result };
          // this.uploadDocumentBtnClick();
        })
        .catch((err:any) => {});
      // await this.actionUpdateDocument(this.documentData)
      //   .then(async (result: any) => {
      //     logger.debug('onSave result:>> ', result);
      //     this.model.id = result.id;
      //     this.documentData = { ...result.result };
      //     this.uploadDocumentBtnClick();
      //   })
      //   .catch((err: any) => {});
    }
  }
  changeVoucherDocumentAddEditDialogOnUpdate(): any {
    // throw new Error('Method not implemented.');
    return new Promise((resolve, reject) => {})
  }
  uploadDocumentBtnClick() {
    this.documentUploadCmpRef.upload();
  }
  async downloadDocument() {
    logger.debug('downloadDocument');
    // await this.actionDownloadDocument(this.model!.file_id);
  }
  deleteDocument() {
    logger.debug('deleteDocument');
    // this.actionDeleteDocument(this.model.file_id).then(async (result: any) => {
    //   this.isDocUploaded = false;
    // });
    // this.model.parentId = '';
  }


  onClickedClose() {
    this.resetDocumentData();
    this.resetDocumentFileData();
    // this.observerForm.reset();
    this.$emit('click:close', { updateList: true });
  }

  /**
   * Reset `documentData` after successful upload, otherwise there is Bug when try to upload next document via dialog (BUG: ID saved without reset, so it does NOT create new document but UPDATE existing)
   */
  private resetDocumentData() {
    // this.documentData = {}; //{ ...DocumentEmpty };
  }

    /**
   * Clear `document-upload` Name/size data before closing the dialog to avoid showing wrong data for new dialog
   */
    private resetDocumentFileData() {
      this.documentFileInfo = {
        documentName: '',
        documentType: '',
        documentSize: '',
      };
    }

  //#endregion

}
